<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form">
            <a-form-item label="区域">
              <a-select
                class="form-control"
                :options="sybList"
                v-decorator="['syb']"
                placeholder="请选择"
                allowClear
              ></a-select>
            </a-form-item>
            <a-form-item label="项目">
              <a-select-pro
                class="form-control"
                v-decorator="['projectCode']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                :dropdownMatchSelectWidth="false"
              ></a-select-pro>
            </a-form-item>
            <a-form-item label="设备状态">
              <a-select
                class="form-control"
                v-decorator="['online']"
                placeholder="请选择"
                allowClear
                :options="typesMap.online"
              ></a-select>
            </a-form-item>
            <a-form-item label="是否拆除">
              <a-select
                class="form-control"
                v-decorator="['remove', { initialValue: 0 }]"
                placeholder="请选择"
                allowClear
                :options="typesMap.remove"
              ></a-select>
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button icon="plus-circle" type="primary" @click="removeDevice" v-action="'AIESBGL_EDIT'">
            拆除设备
          </a-button>
          <a-button icon="export" type="primary" @click="exportExcel">导出</a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="cameraIndexCode"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
            :rowSelection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onRowSelectChange,
              type: 'checkbox',
              columnWidth: 40,
            }"
            :scroll="{ x: 1300 }"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <RemoveDevice ref="RemoveDevice"></RemoveDevice>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import ASelectPro from '@/components/SelectPro.vue'
import { getProjectList, getSybList } from '@/service/getData/aie'
import RemoveDevice from './modules/RemoveDevice.vue'
import { getTypeName } from '@/utils'
import moment from 'moment'
import { download } from '@/service/file'

export default {
  components: {
    ASelectPro,
    RemoveDevice,
  },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '区域',
        width: 130,
        align: 'center',
        dataIndex: 'sybName',
        fixed: 'left',
      },
      {
        title: '项目名称',
        width: 200,
        align: 'center',
        dataIndex: 'prjName',
        fixed: 'left',
      },
      {
        title: '项目编码',
        width: 82,
        align: 'center',
        dataIndex: 'prjCode',
      },
      {
        title: '设备名称',
        width: '20%',
        align: 'center',
        dataIndex: 'cameraIndexName',
      },

      {
        title: '进出方向',
        width: 80,
        align: 'center',
        dataIndex: 'entrance',
        customRender: (text, record) => {
          return (
            <div>
              {text === '0' && <a-tag color="purple">进</a-tag>}
              {text === '1' && <a-tag color="blue">出</a-tag>}
            </div>
          )
        },
      },
      {
        title: '安装时间',
        width: '10%',
        align: 'center',
        dataIndex: 'installTime',
      },
      {
        title: '第一次收货时间',
        width: 120,
        align: 'center',
        dataIndex: 'firstReceivTime',
      },
      {
        title: '拆除时间',
        width: '10%',
        align: 'center',
        dataIndex: 'offTime',
      },
      {
        title: '拆除原因',
        align: 'center',
        width: '10%',
        dataIndex: 'removeReason',
      },
      {
        title: '设备状态',
        align: 'center',
        width: 180,
        dataIndex: 'online',
        customRender: (text, record) => {
          return (
            <div>
              {record.remove ? (
                <div>
                  已拆除
                  <a
                    class="ml-2"
                    onClick={() => {
                      this.restoreDevice(record)
                    }}
                  >
                    恢复
                  </a>
                </div>
              ) : (
                <div>
                  <div>
                    <span
                      class={{
                        'text-green-500': text === '1',
                        'text-red-500': text === '0',
                      }}
                    >
                      {this.getTypeName(text, this.typesMap.online)}
                    </span>
                  </div>
                  {text === '0' && (
                    <div>
                      <div>
                        离线时间|时长：{moment(record.offlineTime).format('YYYY-MM-DD HH:mm:ss')}
                        <span class="mx-2">|</span>
                        {moment(record.offlineTime).fromNow()}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],
      projectList: [],
      sybList: [],
      selectedRowKeys: [],
      selectedRows: [],
    }
  },
  computed: {
    typesMap() {
      return {
        online: [
          {
            label: '在线',
            value: '1',
          },
          {
            label: '离线',
            value: '0',
          },
        ],
        entrance: [
          {
            label: '进',
            value: '0',
          },
          {
            label: '出',
            value: '1',
          },
        ],
        remove: [
          {
            label: '已拆除',
            value: 1,
          },
          {
            label: '未拆除',
            value: 0,
          },
        ],
      }
    },
  },
  methods: {
    onRowSelectChange(keys, rows) {
      this.selectedRowKeys = keys
      this.selectedRows = rows
    },
    moment,
    getTypeName,
    getFormatedPageParams(pageNo, pageSize) {
      const params = this.getPageParams(pageNo, pageSize)
      if (params.remove === 1) {
        params.online = ''
      }
      return params
    },
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post(
          '/aie/tripartite/video/device/web/list',
          this.getFormatedPageParams(_pageNo, _pageSize)
        )
        const list = res.data.list
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async loadSybList() {
      this.sybList = await getSybList()
    },
    async loadProjectList() {
      this.projectList = await getProjectList()
    },
    async removeDevice() {
      await this.getSelectedRowKeys()
      await this.$refs.RemoveDevice.start(this.selectedRows)
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    async restoreDevice(row) {
      await this.$axios.post('/aie/web/video/device/remove', {
        remove: 0,
        cameraIndexCode: row.cameraIndexCode,
      })
      this.$message.success('操作成功!')
      this.doQuery()
    },
    exportExcel() {
      const params = this.getFormatedPageParams(this.pageNo, this.pageSize)
      download('/aie/tripartite/video/device/web/export', params)
    },
  },
  async mounted() {
    this.loadProjectList()
    this.loadSybList()
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
